export * from './actions';

export const initialState: Redux.State['settings'] = {
	anonymousId: '',
	isReferred: false,
	marketCode: undefined,
	pdfUrl: '',
	venueAmenities: [],
};

const settingsReducer = (
	state = initialState,
	action: Settings.ActionTypes,
) => {
	switch (action.type) {
		case 'FETCH_SEARCH_RESULTS_SUCCESS':
			return {
				...state,
				marketCode: action.payload.data.search.marketCode,
			};
		case 'RECEIVE_VENUE_AMENITIES':
			return {
				...state,
				venueAmenities: action.payload,
			};
		case 'SET_ANONYMOUS_ID':
			return {
				...state,
				anonymousId: action.payload,
			};
		case 'SET_IS_REFERRED':
			return {
				...state,
				isReferred: action.payload,
			};
		case 'SET_VENDOR_REFERRED_MARKET_CODE':
			return {
				...state,
				marketCode: action.payload,
			};
		case 'FETCH_PDF_URL':
			return {
				...state,
				pdfUrl: action.payload,
			};
		default:
			return state;
	}
};

export default settingsReducer;
