import {
	ReceiveVendorAction,
	ResetVendorAction,
	VendorActionTypes,
} from '@typings/vendor/actionTypes';
import { Vendor as VendorType } from '../../../types/redux/vendor';
import Vendor from '../../decorators/vendor';

export const VENDOR_LOADED = '@fe-marketplace/vendor/loaded';
export const VENDOR_RESET = '@fe-marketplace/vendor/reset';

export const initialState: VendorType = {
	vendor: null,
	vendorRaw: null,
};

export default function reducer(
	state: Redux.State['vendor'] = initialState,
	action: VendorActionTypes,
) {
	switch (action.type) {
		case VENDOR_LOADED:
			return {
				...state,
				vendor: new Vendor(action.payload),
				vendorRaw: action.payload,
			};
		case VENDOR_RESET:
			return initialState;
		default:
			return state;
	}
}

interface ReceiveVendorPayload {
	data: {
		storefront: Vendor.Raw;
	};
}

export const receiveVendor = (
	response: ReceiveVendorPayload,
): ReceiveVendorAction => ({
	type: VENDOR_LOADED,
	payload: response.data.storefront,
});

export const resetVendor = (): ResetVendorAction => ({ type: VENDOR_RESET });
