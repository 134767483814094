export const clearTransmittedVendors = (
	sentImpressions: VendorImpressions.Impression[],
): VendorImpressions.ClearTransmittedVendors => ({
	type: 'CLEAR_TRANSMITTED_VENDORS',
	sentImpressions,
});

export const queueVendorImpression = (
	impression: VendorImpressions.Impression,
): VendorImpressions.QueueVendorImpression => ({
	type: 'QUEUE_VENDOR_IMPRESSION',
	impression,
});

export const setCurrentTimeout = (
	currentTimeout: number | undefined,
): VendorImpressions.SetCurrentTimeout => ({
	type: 'SET_CURRENT_TIMEOUT',
	currentTimeout,
});

export const setDirectoryStyle = (
	directoryStyle: VendorImpressions.DirectoryStyle,
): VendorImpressions.SetDirectoryStyle => ({
	type: 'SET_DIRECTORY_STYLE',
	directoryStyle,
});
