import initialState from './initialState';

export * from './actions';
export { default as initialState } from './initialState';

const vrmReducer = (state = initialState, action: VRM.ActionTypes) => {
	switch (action.type) {
		case 'vrm/OPEN_MODAL':
			return { ...state, isOpen: true };
		case 'vrm/CLOSE_MODAL':
			return { ...state, isOpen: false };
		case 'vrm/SET_SIMILAR_VENDORS': {
			return {
				...state,
				similarVendors: action.payload,
			};
		}
		case 'vrm/SET_SIMILARITY_FACTORS': {
			return {
				...state,
				factors: action.payload,
			};
		}
		case 'vrm/SET_MATCH_DETAILS_VISIBILITY': {
			return {
				...state,
				isMatchDetailsVisible: action.payload,
			};
		}
		default:
			return state;
	}
};

export default vrmReducer;
