const initialState: Redux.Search = {
	boundingBox: null,
	filterPills: {
		applied: [],
		categories: [],
		deiFilters: [],
		limit: 6,
		staged: {
			add: [],
			remove: [],
			commitChanges: false,
		},
		stagedResultsCount: 0,
		sidebar: {
			applied: [],
			categories: [],
			clearSidebarFlag: '',
			isSidebarOpen: false,
			selected: [],
		},
	},
	filters: { name: '', filters: [] },
	followableFacets: [],
	followableFilters: [],
	isFetching: false,
	isPermitted: false,
	marketCode: '',
	maxResultColumns: 3,
	pagination: {
		page: 1,
		count: 0,
		limit: 30,
	},
	scoreType: 'IVOL',
	searchCopy: '',
	searchFallback: '',
	sizes: {
		header: 0,
		search: 0,
	},
	sort: 'recommended',
	sortVariation: 'banded-distance',
	sortVersion: '1.0',
	total: 0,
	vendors: [],
	supplemental: [],
};

export default initialState;
