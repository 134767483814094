import constants from '../../../containers/widget/constants';
import algorithms from '../../../containers/widget/constants/algorithms';

export const initialState = {
	isFetching: false,
	algorithmType: algorithms.PVREC_CROSS_CAT,
	recommendedVendors: [],
};

export default function pvRecCrossCatVendorsReducer(
	state = initialState,
	action,
) {
	switch (action.type) {
		case constants.FETCH_PVREC_CROSS_CAT_VENDORS:
			return {
				...state,
				isFetching: true,
			};
		case constants.FETCH_PVREC_CROSS_CAT_VENDORS_SUCCESS: {
			if (action.payload.data.errors) {
				return {
					...state,
					isFetching: false,
					recommendedVendors: [],
					errorMessage: action.payload.data.errors[0].message,
				};
			}

			const { recommendations } = action.payload.data.data.pvRecCrossCategory;
			return {
				...state,
				isFetching: false,
				recommendedVendors: recommendations && recommendations[0].profiles,
			};
		}
		case constants.FETCH_PVREC_CROSS_CAT_VENDORS_ERROR:
			return {
				...state,
				isFetching: false,
				recommendedVendors: [],
				errorMessage: action.error,
			};
		default:
			return state;
	}
}
