export const getPagination = (paginationFromApi: Search.Pagination) => ({
	...paginationFromApi,
	page: paginationFromApi.page + 1, // API has 0-based pages, front-end is 1-based.
});

export const getSearchFallback = (
	searchFallbackFromApi: Search.SearchFallback,
) => {
	if (!searchFallbackFromApi) {
		return '/';
	}

	const { citySearch, stateSearch, categorySearch, home } =
		searchFallbackFromApi;

	return (
		[citySearch, stateSearch, categorySearch].find(
			(searchType) => searchType.length,
		) || home
	);
};

const LOCKED_CATEGORIES: Search.FilterPillCategory[] = [
	'Best Of Weddings',
	'Business Attributes',
];

export const categoryToRemove = (
	categories: Search.FilterPillCategory[],
): [Search.FilterPillCategory, number] => {
	for (let i = categories.length - 1; i > 0; i -= 1) {
		const currentCategory = categories[i];
		if (!LOCKED_CATEGORIES.includes(currentCategory)) {
			return [currentCategory, i];
		}
	}
	return [categories[0], 0];
};

const getApplied = (
	searchState: Redux.Search,
	filter: Search.FilterDetails,
) => {
	const filterIds = searchState.filterPills.applied.map((f) => f.id);
	const filterOptions = filter.filterOptions.filter((f) =>
		filterIds.includes(f.id),
	);

	return {
		filterIds,
		filterOptions,
	};
};

export const moveAppliedFiltersToSidebar = (
	searchState: Redux.Search,
	filter: Search.FilterDetails,
): void => {
	const applied = getApplied(searchState, filter);

	searchState.filterPills.sidebar.selected.push(
		...applied.filterOptions.map((f) => f.id),
	);
	searchState.filterPills.sidebar.applied.push(
		...applied.filterOptions.map((o) => ({
			id: o.id,
			name: o.name,
			value: o.singular.slug,
			categorySlug: filter.slug,
		})),
	);
};

export const removeAppliedFiltersFromSidebar = (
	stateCopy: Redux.Search,
	filter: Search.FilterDetails,
): void => {
	// Remove filter pills from more filters
	const { applied } = stateCopy.filterPills.sidebar;
	const appliedFilterIds = applied.map((f) => f.id);
	const appliedFilterOptions = filter.filterOptions.filter((f) =>
		appliedFilterIds.includes(f.id),
	);
	appliedFilterOptions.forEach((f) =>
		applied.splice(
			applied.findIndex((a) => f.id === a.id),
			1,
		),
	);
};

const isFilterPillCategory = (
	category?: string,
): category is Search.FilterPillCategory => !!category;

const moveCategory = (
	filterPills: Search.FilterPills,
	lastFilter: string,
	category?: string,
) => {
	if (!isFilterPillCategory(category)) {
		return;
	}
	if (lastFilter === 'Best Of Weddings') {
		filterPills.categories.splice(
			filterPills.categories.length - 1,
			0,
			category,
		);
		return;
	}

	filterPills.categories.push(category);
};

const replaceSidebarFilters = (searchState: Redux.Search) => {
	// If sidebar only has one filter, replace more filters with it.
	if (searchState.filterPills.sidebar.categories.length === 1) {
		const nextPill = searchState.filterPills.sidebar.categories.shift();
		if (nextPill) {
			searchState.filterPills.categories.push(nextPill);
		}
	}
};

export const moveFilterToPillArea = (searchState: Redux.Search): void => {
	const { filterPills, filters } = searchState;
	const category = searchState.filterPills.sidebar.categories.shift();
	const lastFilter = filterPills.categories[filterPills.categories.length - 1];

	moveCategory(filterPills, lastFilter, category);

	const filter = filters.filters.find((f) => f.name === category);
	if (filter) {
		removeAppliedFiltersFromSidebar(searchState, filter);
	}

	replaceSidebarFilters(searchState);
};
