export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SELECT_VENDOR = 'SELECT_VENDOR';
export const CLEAR_VENDOR = 'CLEAR_VENDOR';
export const HANDLE_EMPTY_VENDOR = 'HANDLE_EMPTY_VENDOR';

export const initialState = {
	vendor: null,
	name: '',
	validity: 'neutral',
	subText: '',
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SELECT_VENDOR:
			return {
				...state,
				vendor: action.vendor,
			};
		case UPDATE_SEARCH:
			return {
				...state,
				name: action.name,
				validity: 'neutral',
			};
		case CLEAR_VENDOR:
			return {
				...state,
				vendor: action.vendor,
			};
		case HANDLE_EMPTY_VENDOR:
			return {
				...state,
				validity: action.validity,
				subText: action.subText,
			};
		default:
			return state;
	}
}

export function selectVendor(vendor, modal) {
	return (dispatch) => {
		dispatch({
			type: SELECT_VENDOR,
			vendor,
			modal,
			validity: 'neutral',
		});
	};
}

export function updateSearch(name) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SEARCH,
			name,
			validity: 'neutral',
		});
	};
}

export function clearVendor(vendor) {
	return (dispatch) => {
		dispatch({
			type: CLEAR_VENDOR,
			vendor,
		});
	};
}

export function handleEmptyVendor() {
	return (dispatch) => {
		dispatch({
			type: HANDLE_EMPTY_VENDOR,
			validity: 'invalid',
			subText: 'Please select a vendor',
		});
	};
}
