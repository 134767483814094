export const SET_SEARCH_PAGE_LOCATION = 'SET_SEARCH_PAGE_LOCATION';

export const initialState = {
	marketCode: null,
	city: null,
	stateCode: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_SEARCH_PAGE_LOCATION:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}

export const getSearchPageLocation = (state) => state.searchPageLocation;
