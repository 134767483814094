export const initialState = {
	id: '',
};

// actions
export const SET_VISITOR_ID = '@fe-marketplace/visitor/id/set';

// reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_VISITOR_ID:
			return {
				...state,
				id: action.payload.id,
			};

		default:
			return state;
	}
}

// action creators
export const setVisitorId = (id) => ({
	type: SET_VISITOR_ID,
	payload: { id },
});

// accessors
export const getVisitorId = (state) => state.visitor.id;
