export const receiveVendorAmenities = (
	payload: Settings.VenueAmenity[],
): Settings.Actions.ReceiveVenueAmenities => ({
	type: 'RECEIVE_VENUE_AMENITIES',
	payload,
});

export const setAnonymousId = (
	payload: string,
): Settings.Actions.SetAnonymousId => ({
	type: 'SET_ANONYMOUS_ID',
	payload,
});

export const setIsReferred = (
	payload: boolean,
): Settings.Actions.SetIsReferred => ({
	type: 'SET_IS_REFERRED',
	payload,
});

export const setVendorReferredMarketCode = (
	payload: string,
): Settings.Actions.SetVendorReferredMarketCode => ({
	type: 'SET_VENDOR_REFERRED_MARKET_CODE',
	payload,
});

export const fetchPdfUrl = (payload: string): Settings.Actions.FetchPdfUrl => ({
	type: 'FETCH_PDF_URL',
	payload,
});
