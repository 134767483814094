import constants from '../../../containers/widget/constants';
import algorithms from '../../../containers/widget/constants/algorithms';

export const initialState = {
	isFetching: false,
	algorithmType: algorithms.SIMILAR,
	recommendedVendors: [],
};

export default function similarVendorsReducer(state = initialState, action) {
	switch (action.type) {
		case constants.FETCH_SIMILAR_VENDORS:
			return {
				...state,
				isFetching: true,
			};
		case constants.FETCH_SIMILAR_VENDORS_SUCCESS:
			if (action.payload.data.errors) {
				return {
					...state,
					isFetching: false,
					recommendedVendors: [],
					errorMessage: action.payload.data.errors[0].message,
				};
			}

			return {
				...state,
				isFetching: false,
				recommendedVendors: action.payload.data.data.similarity.profiles,
			};

		case constants.FETCH_SIMILAR_VENDORS_ERROR:
			return {
				...state,
				isFetching: false,
				recommendedVendors: [],
				errorMessage: action.error,
			};
		default:
			return state;
	}
}
