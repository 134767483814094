export const STATE_LOADED = '@fe-marketplace/states/loaded';
export const ALL_STATES_LOADED = '@fe-marketplace/states/all-loaded';
export const STATE_CITIES_LOADED = 'STATE_CITIES_LOADED';

export const initialState = {
	allStates: [],
	cities: [],
	footerCities: [],
	code: '',
	name: '',
	slug: '',
	topCities: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case STATE_LOADED:
			return {
				...state,
				...action.payload,
			};
		case ALL_STATES_LOADED:
			return {
				...state,
				allStates: action.payload,
			};
		case STATE_CITIES_LOADED:
			return {
				...state,
				footerCities: action.payload,
			};
		default:
			return state;
	}
}

export const getStatesSlug = (state) => state.states.slug;
export const getStateName = (state) => state.states.name;
export const getStateCode = (state) => state.states.code;
export const getTopCities = (state) => state.states.topCities;
export const getCities = (state) => state.states.cities;
export const getFooterCities = (state) => state.states.footerCities;
export const getAllStateSlugs = (state) => state.states.allStates;
