import constants from '../../../containers/widget/constants';
import algorithms from '../../../containers/widget/constants/algorithms';

export const initialState = {
	isFetching: false,
	algorithmType: algorithms.PVREC_ANON,
	recommendedVendors: [],
};

export default function pvRecAnonVendorsReducer(state = initialState, action) {
	switch (action.type) {
		case constants.FETCH_PVREC_ANON_VENDORS:
			return {
				...state,
				isFetching: true,
			};
		case constants.FETCH_PVREC_ANON_VENDORS_SUCCESS:
			return {
				...state,
				isFetching: false,
				recommendedVendors: action.payload.profiles,
			};
		case constants.FETCH_PVREC_ANON_VENDORS_ERROR:
			return {
				...state,
				isFetching: false,
				recommendedVendors: [],
				errorMessage: action.error || action.payload.data.errorMessage,
			};
		default:
			return state;
	}
}
