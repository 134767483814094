import constants from '@containers/widget/constants';
import type { ErrorMessage } from '@typings/api';
import type { Raw as VendorRaw } from '@typings/vendor';
import type { BowVendorsActionTypes } from '@typings/widget/actionTypes';
import algorithms from '../../../containers/widget/constants/algorithms';

export interface BowVendorsState {
	isFetching: boolean;
	algorithmType: typeof algorithms.BOW;
	recommendedVendors: VendorRaw[];
	errorMessage?: ErrorMessage;
}

export const initialState: BowVendorsState = {
	isFetching: false,
	algorithmType: algorithms.BOW,
	recommendedVendors: [],
};

export default function bowVendorsReducer(
	state = initialState,
	action: BowVendorsActionTypes,
) {
	switch (action.type) {
		case constants.FETCH_BOW_VENDORS:
			return {
				...state,
				isFetching: true,
			};
		case constants.FETCH_BOW_VENDORS_SUCCESS:
			return {
				...state,
				isFetching: false,
				recommendedVendors: action.payload,
			};
		case constants.FETCH_BOW_VENDORS_ERROR:
			return {
				...state,
				isFetching: false,
				errorMessage: action.payload,
			};
		default:
			return state;
	}
}
