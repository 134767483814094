import * as constants from './constants';
import { breakpoints, toObject } from './utils';

export * from './actions';

/*
  A reducer to represent the user's viewport.

  isMobile: isMobile will be true if the useragent header is a mobile browser. Implemented to render proper component(s) during SSR.
  greaterThan / lessThan: An object that identifies the window size. Only client side (requires window)
    Properties: extraSmall, small, medium, large
    Usage: state.greaterThan.small ? displayDesktop() : displayMobile()
*/

export const initialState = {
	isClientSide: false,
	isMobile: true, // Mobile first!
	isMobileApp: false,
	isTablet: false,
	greaterThan: toObject(breakpoints, true, 360),
	lessThan: toObject(breakpoints, false, 360),
};

const viewportReducer = (state = initialState, action) => {
	switch (action.type) {
		case constants.SET_WINDOW_WIDTH: {
			return {
				...state,
				isClientSide: !!action.isClientSide,
				greaterThan: toObject(breakpoints, true, action.windowWidth),
				lessThan: toObject(breakpoints, false, action.windowWidth),
			};
		}
		case constants.SET_IS_MOBILE: {
			return {
				...state,
				isMobile: action.isMobile,
			};
		}
		case constants.SET_IS_MOBILE_APP: {
			return {
				...state,
				isMobileApp: action.isMobileApp,
			};
		}
		case constants.SET_IS_TABLET: {
			return {
				...state,
				isTablet: action.isTablet,
			};
		}
		default:
			return state;
	}
};

export default viewportReducer;
