import { cloneDeep } from 'lodash';
import initialState from './initialState';

const vendorImpressionReducer = (
	state = initialState,
	action: VendorImpressions.ActionTypes,
) => {
	const stateCopy = cloneDeep(state);
	switch (action.type) {
		case 'QUEUE_VENDOR_IMPRESSION': {
			stateCopy.impressionQueue.push(action.impression);
			break;
		}
		case 'CLEAR_TRANSMITTED_VENDORS': {
			const { sentImpressions } = action;
			stateCopy.impressionQueue = stateCopy.impressionQueue.filter(
				(impression) =>
					sentImpressions.findIndex((sent) => sent.id === impression.id) === -1,
			);
			break;
		}

		case 'SET_DIRECTORY_STYLE': {
			stateCopy.directoryStyle = action.directoryStyle;
			break;
		}

		case 'SET_CURRENT_TIMEOUT': {
			const { currentTimeout } = action;
			stateCopy.currentTimeout = currentTimeout;
			break;
		}

		default:
			return state;
	}
	return stateCopy;
};

export { default as initialState } from './initialState';
export default vendorImpressionReducer;
