import { getCategory } from '../category';
import { getCities, getStateCode, getTopCities } from '../states';
import { getVisitorId } from '../visitor';

export const RECOMMENDATIONS_LOADED =
	'@fe-marketplace/statePage/recommendations_loaded';

export const initialState = {
	recommendedVendorsByCity: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case RECOMMENDATIONS_LOADED: {
			const { city, profiles, total, isPermitted } = action.payload;

			return {
				...state,
				recommendedVendorsByCity: {
					...state.recommendedVendorsByCity,
					[city]: {
						profiles,
						total,
						isPermitted,
					},
				},
			};
		}
		default:
			return state;
	}
}

export function fetchRecommendationsByCity(methods, city, limit = 4) {
	return async (dispatch, getState) => {
		const state = getState();
		const category = getCategory(state);
		const stateCode = getStateCode(state);
		const visitorId = getVisitorId(state);
		const { experiments } = state;
		const response = await methods.recommendedSearch({
			categoryGuid: category.id,
			city,
			state: stateCode,
			limit,
			visitorId,
			experiments,
		});

		const isPermitted = await methods.isOnSeoPermitted({
			categoryGuid: category.id,
			city,
			state: stateCode,
		});

		return dispatch({
			type: RECOMMENDATIONS_LOADED,
			payload: {
				city,
				profiles: response?.data?.search?.profiles,
				total: response?.data?.search?.total?.profiles,
				isPermitted,
			},
		});
	};
}

export function fetchAllRecommendations(methods) {
	return async (dispatch, getState) => {
		const state = getState();
		const topCities = getTopCities(state);
		const cities = getCities(state);

		const topCitiesRecommendations = topCities.map((city) =>
			dispatch(fetchRecommendationsByCity(methods, city, 4)),
		);

		const citiesRecommendations = cities.map((city) =>
			dispatch(fetchRecommendationsByCity(methods, city, 2)),
		);

		return Promise.all([...topCitiesRecommendations, ...citiesRecommendations]);
	};
}

export const getRecommendedVendorProfilesByCity = (state, city) =>
	state?.statePage?.recommendedVendorsByCity[city]?.profiles || [];

export const getRecommendedVendorTotalByCity = (state, city) =>
	state?.statePage?.recommendedVendorsByCity[city]?.total || 0;

export const isRecommendationPermittedByCity = (state, city) =>
	state?.statePage?.recommendedVendorsByCity[city]?.isPermitted || true;
