import { waitForAnalytics } from '../../../utils';
import * as actionCreators from './actionCreators';

export const getAnonymousId = async (): Promise<string> => {
	const analyticsReady = await waitForAnalytics();

	if (!analyticsReady) {
		return '';
	}

	return window.analytics.user().anonymousId();
};

export const requestAnonymousId =
	(): Redux.ThunkResult<Promise<string>> =>
	async (dispatch, getState): Promise<string> => {
		const state = getState();
		const { anonymousId } = state.settings;

		if (anonymousId !== null && typeof anonymousId === 'string') {
			return anonymousId;
		}

		const nextAnonymousId = await getAnonymousId();
		if (nextAnonymousId) {
			dispatch(actionCreators.setAnonymousId(nextAnonymousId));
		}

		return nextAnonymousId;
	};
