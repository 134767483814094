export function openVrmModal() {
	return { type: 'OPEN_VRM_MODAL' };
}

export function closeVrmModal() {
	return { type: 'CLOSE_VRM_MODAL' };
}

export const vrmInitialState = {
	isOpen: false,
};

export default function reducer(state = vrmInitialState, action) {
	switch (action.type) {
		case 'OPEN_VRM_MODAL':
			return { ...state, isOpen: true };
		case 'CLOSE_VRM_MODAL':
			return { ...state, isOpen: false };
		default:
			return state;
	}
}
