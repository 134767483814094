import { Logger } from '@utils/logger/logger';

const position = (
	index: number,
	details: VendorImpressions.ImpressionDetails,
) => {
	Logger.error({
		log_message: 'Impression Position is invalid',
		errorToLog: new Error(
			`Impression Position is invalid, triggered for ${details.impressionType}`,
		),
		optionalParams: {
			details: JSON.stringify(details),
			index,
		},
		newRelic: true,
	});
	return index;
};

const report = {
	position,
};

export default report;
