import constants from '../../pages/VendorsSearch/constants';

export const initialState = {
	nextUrl: null,
	previousUrl: null,
	canonicalUrl: null,
	seoFilter: '',
};

export default function seoFilterReducer(state = initialState, action) {
	switch (action.type) {
		case constants.UPDATE_SEO_FILTER_VALUE:
			return {
				...state,
				seoFilter: action.payload.seoFilter,
			};
		case constants.UPDATE_SEO_CANONICAL_URLS:
			return {
				...state,
				canonicalUrl: action.payload.canonicalUrl,
				nextUrl: action.payload.nextUrl,
				previousUrl: action.payload.previousUrl,
			};
		default:
			return state;
	}
}
