import * as constants from './constants';

export const setWindowWidth = (windowWidth, isClientSide = true) => ({
	type: constants.SET_WINDOW_WIDTH,
	windowWidth,
	isClientSide,
});

export const setIsMobile = (isMobile) => ({
	type: constants.SET_IS_MOBILE,
	isMobile,
});

export const setIsMobileApp = (isMobileApp) => ({
	type: constants.SET_IS_MOBILE_APP,
	isMobileApp,
});

export const setIsTablet = (isTablet) => ({
	type: constants.SET_IS_TABLET,
	isTablet,
});
