import cssBreakpoints from '@xo-union/tk-ui-breakpoints/lib/variables.css';

const sizes = [
	{
		name: 'extraSmall',
		code: 'sm',
	},
	{
		name: 'small',
		code: 'md',
	},
	{
		name: 'medium',
		code: 'lg',
	},
	{
		name: 'large',
		code: 'xl',
	},
];

const breakpointFor = (size) =>
	Number.parseInt(cssBreakpoints[`var-${size}`], 10) - 1;

export const breakpoints = sizes.map((size) => ({
	...size,
	breakpoint: breakpointFor(size.code),
}));

export const toObject = (arr, greaterThan, width) => {
	const rv = {};
	for (const item of arr) {
		rv[item.name] = greaterThan
			? item.breakpoint < width
			: item.breakpoint > width;
	}
	return rv;
};
