// @ts-check

import { combineReducers } from 'redux';
import bowVendorsReducer, {
	initialState as bowVendorsReducerInitialState,
} from './bow_vendors';
import pvRecAnonVendorsReducer, {
	initialState as pvRecAnonVendorsReducerInitialState,
} from './pvrec_anon_vendors';
import pvRecCrossCatVendorsReducer, {
	initialState as pvRecCrossCatVendorsReducerInitialState,
} from './pvrec_cross_cat_vendors';
import similarVendorsReducer, {
	initialState as similarVendorsReducerInitialState,
} from './similar_vendors';

/** @type {Redux.Widget} */
export const initialState = {
	bowVendorsReducer: bowVendorsReducerInitialState,
	pvRecAnonVendorsReducer: pvRecAnonVendorsReducerInitialState,
	pvRecCrossCatVendorsReducer: pvRecCrossCatVendorsReducerInitialState,
	similarVendorsReducer: similarVendorsReducerInitialState,
};

const combinedReducer = combineReducers({
	similarVendorsReducer,
	bowVendorsReducer,
	pvRecAnonVendorsReducer,
	pvRecCrossCatVendorsReducer,
});

export default combinedReducer;
