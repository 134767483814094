import search from '@api/search';
import { generateExperimentHeader } from '@server/methods/search/utils';
import FetchSearchResultsException from '../../../server/exceptions/FetchSearchResultsException';
import NotFoundException from '../../../server/exceptions/NotFoundException';

export const VENDORS_LOADED = '@fe-marketplace/vendors/loaded';

export const initialState = {
	profiles: [],
	total: 0,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case VENDORS_LOADED:
			return {
				...state,
				profiles: action.payload.profiles,
				total: action.payload.total.profiles,
			};
		default:
			return state;
	}
};

const checkForSearchResponseErrors = (response, options) => {
	if ('errors' in response) {
		throw new FetchSearchResultsException(response.errors[0].message, options);
	}
};

export const fetchVendors = (args) => async (dispatch, getState) => {
	const { categoryGuid, city, state, limit = 30 } = args;
	const stateTree = getState();
	const {
		category: { code },
		visitor: { id: visitorId },
		experiments,
	} = stateTree;
	const options = {
		categoryCode: code,
		categoryGuid,
		city,
		state,
		limit,
		visitorId,
	};

	try {
		const headers = generateExperimentHeader(experiments);
		const searchResponse = await search(options, headers);
		checkForSearchResponseErrors(searchResponse, options);
		dispatch({
			type: VENDORS_LOADED,
			payload: searchResponse.data.data.search,
		});
	} catch (error) {
		if (error.message === 'Invalid Location') {
			throw new NotFoundException('Not Found', options);
		}
		throw new FetchSearchResultsException(error.message, options);
	}
};

// Will only be called for server side rendering.
export const updateVendorsFromCache = (response) => {
	if (response.errors) {
		return {
			type: `${VENDORS_LOADED}_ERROR`,
			error: response.errors[0].message || 'Unknown',
		};
	}

	if (!response?.data?.search) {
		return {
			type: `${VENDORS_LOADED}_ERROR`,
			error: 'Empty search response. No error message',
		};
	}

	return {
		type: VENDORS_LOADED,
		payload: response.data.search,
	};
};

export const getVendorProfiles = (state) => state.vendors.profiles;
export const getVendorTotal = (state) => state.vendors.total;

export default reducer;
