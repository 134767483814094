import { Logger } from '@utils/logger/logger';
import WeddingAPIClient from '@xo-union/sdk-weddings';

import { weddingsApiKey, weddingsRootUrl } from '../../../settings';

export const FETCH_WEDDING = 'FETCH_WEDDING';
export const FETCH_WEDDING_SUCCESS = 'FETCH_WEDDING_SUCCESS';
export const FETCH_WEDDING_FAILURE = 'FETCH_WEDDING_FAILURE';

function createClient(token) {
	return new WeddingAPIClient({
		host: weddingsRootUrl,
		apiKey: weddingsApiKey,
		membershipSessionToken: token,
	});
}

export const initialState = {
	weddingMarket: {},
	error: null,
	isFetching: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_WEDDING:
			return {
				...state,
				isFetching: true,
			};
		case FETCH_WEDDING_SUCCESS:
			return {
				...state,
				isFetching: false,
				weddingMarket: action.response.wedding.market,
			};
		case FETCH_WEDDING_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.error,
			};
		default:
			return state;
	}
}

export function fetchWedding(memberId, token) {
	const weddingClient = createClient(token);
	return (dispatch) => {
		dispatch({ type: FETCH_WEDDING });
		return weddingClient.getWeddingByMemberId(memberId).then(
			(response) => {
				dispatch({ type: FETCH_WEDDING_SUCCESS, response });
			},
			(error) => {
				dispatch({ type: FETCH_WEDDING_FAILURE, error });
				Logger.error({
					log_messahge: 'Error fetching wedding',
					errorToLog: error,
					newRelic: true,
				});
			},
		);
	};
}

export const fetchingWedding = (state) => state.wedding.isFetching;
export const getWeddingCity = (state) =>
	state.wedding &&
	state.wedding.weddingMarket &&
	state.wedding.weddingMarket.city;
export const getWeddingMarketCode = (state) =>
	state.wedding.weddingMarket && state.wedding.weddingMarket.code;
export const getWeddingState = (state) =>
	state.wedding &&
	state.wedding.weddingMarket &&
	state.wedding.weddingMarket.state;
